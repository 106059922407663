import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Header, Button } from 'react-bootstrap';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import utf8 from "utf8";
// Images
import AboutPic7 from "../../../images/about/bizkimiz.png"
import { withTranslation } from 'react-i18next';


class MissionVision extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getAboutCastor/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render() {
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return (
			<>


				{this.state.data2.map((item) => (
					<div className="row">
						<div className="col-md-6 section-sp1 p-r30 p-xs-r15">
							<div className="heading-bx">
								<h6 className="title-ext m-b0">CASTOR</h6>
								<h2 className="title-head m-b0">{t('BizKimiz')}</h2>
								
								<div className=" sepimg"></div>
								{item.AboutDesc != null ? <ReactHtml html={utf8.decode((base64.decode((item.AboutDesc))))} />: null}
							</div> 
							<Accordion className="ttr-accordion white faq-bx" defaultActiveKey="0">
							{item.Vision != null ?<Card>
									<Accordion.Toggle as={Card.Header} eventKey="0">
										<Link to="#">{t('Vizyonumuz')}</Link>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body>
											{item.Vision}

										</Card.Body>
									</Accordion.Collapse>
								</Card> : null }
								{item.Mission != null ?<Card>
									<Accordion.Toggle as={Card.Header} eventKey="1">
										<Link to="#">{t('Misyonumuz')}</Link>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="1">
										<Card.Body>
											{item.Mission}
										</Card.Body>
									</Accordion.Collapse> 
								</Card>: null}
								{item.ShortDesc != null ? <Card>
									<Accordion.Toggle as={Card.Header} eventKey="2">
										<Link to="#">{t('BizKimiz')}</Link>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="2">
										<Card.Body>
											{item.ShortDesc}
										</Card.Body>
									</Accordion.Collapse>
								</Card> : null}
							</Accordion>

						</div>
						<div className="col-md-6 p-lr0 ">
							<img src={AboutPic7} className="mvimg" alt="" />
						</div>
					</div>


				))}
			</>
		);
	}
}


export default withTranslation()(MissionVision); // withTranslation ile sarmalandı

