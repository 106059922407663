import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import Slider_ from "react-slick";
// Elements
import Slider from '../elements/slider/slider1';
import ServiceSlider1 from '../elements/services/service-slider-1';
import ServiceSlider100 from '../elements/services/service-slider-100';
import About from '../elements/about/about1';
import "toastr/build/toastr.min.css";

// Images

import moment from 'moment';
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş


class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {


			data3: [],
			dataMuh: [],
			dataDanismanlik: [],

			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}


	componentDidMount() {

		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getBlogCastor/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


		const apiUrlDanis = "https://getjob.stechomeyazilim.info:5101/getConsultancyCategoryCastor/select/";
		const apiUrlMuh = "https://getjob.stechomeyazilim.info:5101/getEngineeringServicesCategoryCastor/select/";

		// Fetch data for consultancy
		fetch(apiUrlDanis)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ dataDanismanlik: data3 });
			})
			.catch((error) => {
				console.error("Consultancy API'den veri çekme hatası:", error);
			});

		// Fetch data for engineering services
		fetch(apiUrlMuh)
			.then((response) => response.json())
			.then((dataMuh) => {
				this.setState({ dataMuh: dataMuh });
			})
			.catch((error) => {
				console.error("Engineering Services API'den veri çekme hatası:", error);
			});


	}
	render() {
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 7,
			slidesToScroll: 1,
			responsive: [

				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		const language = i18n.language

		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<Slider />

					{/* <!-- Main Slider --> */}
					<div className="content-block" id="content-area">

						<div className="section-area process-area">
							<div className="container">
								<ServiceSlider100 />
							</div>
						</div>


						<div className="section-area section-sp3 bg-white">
							<div className="container">
								<About />
							</div>
						</div>


						{/*
			
						<div className="section-area bg-white section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-4 col-md-12">
										<div className="heading-bx m-b20 text-center  text-lg-left m-md-b30">
											<h6 className="title-ext m-b0">See Our Faq</h6>
											<h2 className="title-head m-b0">Provides Best Service</h2>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Our purpose follow leading design, engineering and industrial manufacturing company specialising in mining, oil, gas and engineering.</p>
											<Link to="get-in-touch" className="btn">View Al Post</Link>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-sm-6">
										<div className="service-box text-center m-xs-b30">
											<div className="service-media">
												<img src={ServicesPic1} alt=""/>
												<div className="service-inner">
													<Link to="#" className="btn white">View All</Link>
												</div>
											</div>
											<div className="service-info">
												<h4 className="title">Fuel & Gas Management</h4>
												<p>Our purpose follow leading design, engineering and industrial manufacturing company </p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-sm-6">
										<div className="service-box text-center">
											<div className="service-media">
												<img src={ServicesPic2} alt=""/>
												<div className="service-inner">
													<Link to="#" className="btn white">View All</Link>
												</div>
											</div>
											<div className="service-info">
												<h4 className="title">Fuel & Gas Management</h4>
												<p>Our purpose follow leading design, engineering and industrial manufacturing company </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
								<div className="section-area bg-primary section-sp2 client-appoint-box">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="heading-bx text-white">
										

											<h6 className="title-ext m-b0">{t('İletişim')}</h6>
											<h2 className="title-head m-b0">CASTOR {t('Danışmanlık')}</h2>
											<div className="ttr-separator sepimg2"></div>
										</div>
										<div className="swiper-container client-area-box">		
											<Testimonial />
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<form className="ajax-form" action="script/contact.php">
											<div className="apoint-btn-box">{t('İletişim')}</div>
											<div className="ajax-message"></div>
											<div className="heading-bx text-white">
										
												<h2 className="title-head m-b0">{t('BU')}</h2>
												<div className="ttr-separator sepimg2"></div>
											</div>
											<div className="row placeani appoint-bx1">
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="name" type="text" required className="form-control valid-character" placeholder={t('İsminiz')}/>
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx"> 
															<input name="email" type="email" className="form-control" required  placeholder={t('Emailiniz')}/>
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="phone" type="text" required className="form-control int-value" placeholder={t('Telefon')}/>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<div className="input-group input-bx">
															<textarea name="message" rows="4" className="form-control" placeholder={t('Mesaj')}required ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<button name="submit" type="submit" value="Submit" className="btn button-md">{t('Gönder')}</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						
						
						*/}




					{/* 	<div className="section-area bg-white section-sp1 blog-area">
							<div className="container">
								<div className="row heading-bx align-items-end">
									<div className="col-lg-12 col-md-9">
										<h2 className="title-head m-b0">{t('DH')}</h2>
						
									</div>

								</div>

								<div className="section-area section-sp1 bg-white">


									<div className="container">
										<div className="row">
											{this.state.dataDanismanlik.slice(0, 3).map((item) => (
												<div className="col-lg-4 col-md-6 wow fadeInUp m-md-b30" data-wow-delay="0.3s">
													<div className="feature-container feature-bx2">
														<div className="feature-lg text-white m-b20">
															<span className="icon-cell"><i className="ti-pin-alt"></i></span>
														</div>
														<div className="icon-content">
															<h4 className="ttr-tilte">{language === "en" ? item.Consultancy_Category_TitleEng : item.Consultancy_Category_Title}</h4>
															<Link to={{ pathname: '/danismanlikhizmetleri_',state: { _item: item.Consultancy[0], _item2: item } }} className="feature-btn"><i className="la la-long-arrow-alt-right"></i></Link>
														</div>
														<i className="ti-pin-alt bg-icon"></i>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>



							</div>
						</div>

						<div className="section-area bg-white section-sp1 blog-area">
							<div className="container " >
								<div className="row heading-bx align-items-end">
									<div className="col-lg-12 col-md-9">
										<h2 className="title-head m-b0">{t('MH')}</h2>
					
									</div>

								</div>
								<div className="row "  >

									{this.state.dataMuh.slice(0, 3).map((item) => (
										<div className="col-lg-4 col-md-6 wow fadeInUp m-md-b30" data-wow-delay="0.3s">
											<div className="feature-container feature-bx2">
												<div className="feature-lg text-white m-b20">
													<span className="icon-cell"><i className="ti-settings"></i></span>
												</div>
												<div className="icon-content">
													<h4 className="ttr-tilte">{language === "en" ? item.EngineeringServices_Category_TitleEng : item.EngineeringServices_Category_Title}</h4>
													<Link  to={{ pathname: '/muhendislikhizmetleri_', state: { _item: item.EngineeringServices.length> 0? item.EngineeringServices[0] : [], _item2: item } }} className="feature-btn"><i className="la la-long-arrow-alt-right"></i></Link>
												</div>
												<i className="ti-settings bg-icon"></i>
											</div>
										</div>
									))}






								</div>
							</div>
						</div>

						<div className="section-area bg-white section-sp1 blog-area">
							<div className="container">
								<div className="row heading-bx align-items-end">
									<div className="col-lg-8 col-md-9">
										<h6 className="title-ext m-b0">BLOG</h6>
										<h2 className="title-head m-b0">{t('SonBlog')}</h2>
								
									</div>
									<div className="col-lg-4 col-md-3">
										<Link to="blog" className="btn float-right">{t('DahaFazlası')}</Link>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										{this.state.data3.slice(0, 1).map((item) => (
											<div className="recent-news">
												<div className="action-box">
													<img src={this.state.imageUrl + item.Blog_Image} alt="" />
												</div>
												<div className="info-bx">
													<ul className="media-post">
														<li className="date"><Link to="#"><i className="fa fa-calendar-o"></i>{moment(item.Created_DateTime).format('YYYY-MM-DD')}</Link></li>
													</ul>
													{language == "en" ?
														<h3 className="post-title"><Link to={{ pathname: '/blogdetay', state: { _item: item } }} >{item.BlogTitleEng}</Link></h3> :
														<h3 className="post-title"><Link to={{ pathname: '/blogdetay', state: { _item: item } }} >{item.Title}</Link></h3>
													}
												</div>
											</div>
										))}
									</div>
									<div className="col-md-6">
										<div className="side-post">
											{this.state.data3.slice(1, 3).map((item) => (

												<div className="blog-post blog-md clearfix wow fadeInUp" data-wow-delay="0.2s">
													<div className="ttr-post-media">
														<Link to={{ pathname: '/blogdetay', state: { _item: item } }} ><img src={this.state.imageUrl + item.Blog_Image} alt="" /></Link>
													</div>
													<div className="ttr-post-info">
														<ul className="media-post">
															<li className="date">
																<Link to={{ pathname: '/blogdetay', state: { _item: item } }} >
																	<i className="fa fa-calendar-o"></i>{moment(item.Created_DateTime).format('YYYY-MM-DD')}</Link></li>
														</ul>
														<h4 className="post-title"><Link to={{ pathname: '/blogdetay', state: { _item: item } }}>{item.Title}</Link></h4>
													</div>
												</div>

											))}
										</div>
									</div>
								</div>
							</div>
						</div>
*/}
					</div>

				</div>


				<div className="section-area bg-white section-sp1 blog-area">
					<div className="container">
						<ServiceSlider1 />
					</div>
				</div>

				<Footer1 />
			</>
		);
	}
}

export default withTranslation()(Index); // withTranslation ile sarmalandı
