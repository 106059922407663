import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

import Banner1 from '../../images/banner/banner1.jpg';
import ReactHtml from 'raw-html-react';
import { withTranslation } from 'react-i18next';
import utf8 from "utf8";

import base64 from 'react-native-base64';
class SolutionAgriculturalEngineering extends Component {


  constructor(props) {
    super(props);
    this.state = {
      data3: [],
      data4: [],
      imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
    }
    this.handleRedirect = this.handleRedirect.bind(this);

  }


  handleRedirect(item3,_item) {

    const { _item2 } = this.props.location.state;

    const newPath = {
      pathname: '/muhendislikhizmetleri_',
      state: {
        _item: item3,
        _item2:_item2
      }
    };
    this.props.history.push(newPath);
  }

  componentDidMount() {

    
    const apiUrl4 = "https://getjob.stechomeyazilim.info:5101/getEngineeringServicesCategoryCastor/select/";

    // Fetch data for consultancy


    // Fetch data for engineering services
    fetch(apiUrl4)
      .then((response) => response.json())
      .then((data4) => {
        this.setState({ data4 });
      })
      .catch((error) => {
        console.error("Engineering Services API'den veri çekme hatası:", error);
      });

  }



  render() {

    const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir
    const language = i18n.language;
    const { _item } = this.props.location.state;
    const { _item2 } = this.props.location.state;
    console.log("l1n21l3i",_item)

    const decodedConsultancyDescTr = (_item.ConsultancyDesc);
    const decodedConsultancyDescEng = (_item.ConsultancyDescEng);

    return (
      <>
        <Header1 />

        {/* <!-- Content --> */}
        <div className="page-content bg-white">
          <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: `url(${Banner1})` }}>
            <div className="container">
              <div className="page-banner-entry">
              <h1 className="text-white">{t('MH')}</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
                    <li>{t('MH')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block" id="content-area">
            <div className="section-area section-sp2 bg-fix bg-white">
              <div className="container about-video">
                <div className="row">
                  <div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                    <aside className="sticky-top">
                      <div className="widget">
                        <ul className="service-list">


                          {_item2.EngineeringServices.map((item3) => (
                            <li><button onClick={() => this.handleRedirect(item3,_item2)} >
                              {language == "en" ?
                                <span>{item3.TitleEng}</span> :
                                <span>{item3.Title}</span>}

                              <i className="fa fa-angle-right"></i> </button></li>
                          ))}

                        </ul>
                      </div>


                    </aside>
                  </div>
                  <div className="col-lg-8 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">
                    <div className="heading-bx text-left m-b20">
                      {language == "en" ?
                        <h3 className="title-head m-b0">{_item.TitleEng}</h3> :
                        <h3 className="title-head m-b0">{_item.Title}</h3>
                      }
              
                    </div>
                    <div className="row ">



                      <div className="col-md-12 m-b15">
                        <img
                          src={this.state.imageUrl + _item.ConsultancyImage}
                          className="radius-sm imghight"
                          alt=""
                          style={{
                            height: '300px', // Sabit yükseklik değeri (istediğiniz değeri girebilirsiniz)
                            width: '100%',
                            objectFit: 'cover'
                          }}
                        />

                      </div>




                    </div>
                    {_item2 != null ?
                      language == "en" ?
                        <ReactHtml html={utf8.decode(base64.decode(decodedConsultancyDescEng))} /> :
                        <ReactHtml html={utf8.decode(base64.decode(decodedConsultancyDescTr))} /> : null}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer1 />
      </>
    );
  }
}

export default withTranslation()(SolutionAgriculturalEngineering); // withTranslation ile sarmalandı
