import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import BlogSidebar from '../elements/blog/blog-sidebar';


import moment from 'moment';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import { withTranslation } from 'react-i18next';
import utf8 from "utf8";

class BlogDetailsSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
	
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		}
	}



	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		const { _item } = this.props.location.state;

		const language = i18n.language

		const decodedConsultancyDescTr = (_item.Blog_Desc);
		const decodedConsultancyDescEng = (_item.BlogDescEng);
	
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Blog Detay</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Blog Detay</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-8 col-xl-8 m-md-b50 wow fadeInUp" data-wow-delay="0.2s">
										{/* <!-- blog start --> */}
										<div className="blog-lg blog-single">
											<div className="action-box blog-lg">
												<img src={this.state.imageUrl+_item.Blog_Image} alt=""/>
											</div>
											<div className="info-bx">
												
												<div className="ttr-post-title">
													{language== "en" ? 
													<h4 className="post-title">{_item.BlogTitleEng}</h4> :
													<h4 className="post-title">{_item.Title}</h4>
												}
												</div>
												<div className="ttr-post-text">
														<blockquote className="wp-block-quote">
														{language== "en" ? 
														<p>{_item.ShortDescEng} </p> :
														<p>{_item.ShortDesc} </p>
														}
													</blockquote>
												
													{language == "en" ? 
                     <ReactHtml html={utf8.decode((base64.decode(decodedConsultancyDescEng)))}  /> :
                     <ReactHtml html={utf8.decode((base64.decode(decodedConsultancyDescTr)))}  />}

           
												</div>
												<div className="ttr-divider bg-gray"><i className="icon-dot c-square"></i></div>
											
											</div>
										</div>
										
										{/* <!-- blog END --> */}
									</div>
									<div className="col-md-12 col-lg-4 col-xl-4 sticky-top wow fadeInUp" data-wow-delay="0.4s">
										<BlogSidebar />
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default withTranslation()(BlogDetailsSidebar); // withTranslation ile sarmalandı
