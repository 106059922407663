import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
class ServiceSlider1 extends Component{
	
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

				
		return(
			<>
			
				<Slider {...settings} className="slider-sp0 arrow-none slider-process">
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-world"></i>{t('BizKimiz')}</h5>
							<p>{t('HDFB')}</p>
							<Link style={{color:'white'}} to="nedenbiz" >{t('Detay')} <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-world"></i>{t('İletişim')}</h5>
							<p>{t('BUİ')}</p>
							<Link style={{color:'white'}} to="iletisim" >{t('Detay')}<i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-comment"></i> Blog</h5>
							<p>{t('BY')}</p>
							<Link style={{color:'white'}} to="blog" >{t('Detay')} <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-hand-point-up"></i> {t('TeklifAl')}</h5>
							<p>{t('SUT')}</p>
							<Link style={{color:'white'}} to="teklifal" >{t('Detay')}<i className="ti-arrow-right"></i></Link>
						</div>
					</div>
				</Slider>
			
			</>
		);
	}
}
export default withTranslation()(ServiceSlider1); // withTranslation ile sarmalandı

