import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import AboutHead from '../elements/about/about-head';
import Testimonial2 from '../elements/testimonial/testimonial2';
import MissionVision from '../elements/mission-vision/mission-vision';
import Services2 from '../elements/services/services-2';
import Counter from '../elements/counter/counter';
import Banner1 from '../../images/banner/banner1.jpg';
import TeamPic1 from '../../images/team/pic1.jpg';
import TeamPic2 from '../../images/team/pic2.jpg';
import TeamPic3 from '../../images/team/pic3.jpg';
import TeamPic4 from '../../images/team/pic4.jpg';
import TeamPic5 from '../../images/team/pic5.jpg';
import TeamPic6 from '../../images/team/pic6.jpg';
// Images

import BackBg1 from "../../images/background/bg1.jpg"
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
class About1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
	
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {
		

			const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getTeamCastor/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


	}
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url(" + Banner1 + ")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t('BizKimiz')}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
										<li>{t('BizKimiz')}</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
					
						

						
						<div className="section-area bg-gray">
							<div className="container">
								<MissionVision />
							</div>
						</div>



					{/*	
						<Counter />
					
						
						<div className="section-area section-sp2 ovbl-dark bg-gray parallax" style={{backgroundImage: "url("+BackBg1+")", backgroundPosition: "top"}}>
							<div className="container">
								<div className="heading-bx text-white text-center">
									<h6 className="title-ext m-b0 "><span className="text-primary">Clients Says</span></h6>
									<h2 className="title-head m-b0">What clients talk about us</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<Testimonial2 />
							</div>
						</div>
							*/}
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}
export default withTranslation()(About1); // withTranslation ile sarmalandı
