import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'; // React ikonlarını içe aktar

class ServiceSlider1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],

		};
	}

	componentDidMount() {

		const apiUrl4 = "https://getjob.stechomeyazilim.info:5101/getEngineeringServicesCategoryCastor/select/";
		const apiUrl5 = "https://getjob.stechomeyazilim.info:5101/getConsultancyCategoryCastor/select/";
		// Fetch data for consultancy


		// Fetch data for engineering services
		fetch(apiUrl4)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
				console.log("data", data);
			})
			.catch((error) => {
				console.error("Engineering Services API'den veri çekme hatası:", error);
			});


		fetch(apiUrl5)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
				console.log("data", data2);
			})
			.catch((error) => {
				console.error("Consultancy Services API'den veri çekme hatası:", error);
			});


	}

	render() {

		const PrevArrow = (props) => {
			const { className, style, onClick } = props;
			return (
				<div className={className} style={{ ...style, display: "flex", alignItems: "center", justifyContent: "center", left: "-20px", top: "40%", transform: "translateY(-50%)" }} onClick={onClick}>
				<FaAngleLeft />
			
			  </div>
			);
		  };
		  
		  // Sağ kaydırma ikonu bileşeni
		  const NextArrow = (props) => {
			const { className, style, onClick } = props;
			return (
				<div className={className} style={{ ...style, display: "flex", alignItems: "center", justifyContent: "center", right: "-20px", top: "40%", transform: "translateY(-50%)" }} onClick={onClick}>
				<FaAngleRight />
			  </div>
			);
		  };

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 7,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1299,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 380,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 380,
					settings: {
						slidesToShow: 1,
					}
				}
			],
			prevArrow: <PrevArrow />,
			nextArrow: <NextArrow />
		};
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		const language = i18n.language

		return (
			<>

				<Slider {...settings} className="slider-sp0 arrow-none slider-process">


				
					{this.state.data2.map((item) => (
						<div className="slider-item">
							<div className="process-box">
								<h5 className="title">{language === "en" ? item.Consultancy_Category_TitleEng : item.Consultancy_Category_Title}</h5>

								<div className="process-btn">
  {item.Consultancy.map((item2, index) => (
    <React.Fragment key={index}>
      <Link  style={{ 
		    textTransform: 'capitalize' ,
    color: 'white',
    fontFamily: 'Noto Sans', // Kullanmak istediğiniz fontun adını belirtin
    fontSize: '16px', // Yazı tipi boyutunu belirleyebilirsiniz
    fontWeight: 'bold' // Yazı tipi kalınlığını belirleyebilirsiniz
  }} to={{ pathname: '/danismanlikhizmetleri_', state: { _item: item.Consultancy[0], _item2: item } }}>
        • {language === "en" ? item2.TitleEng_Short : item2.Title_Short}
      </Link>
      <br />
    </React.Fragment>
  ))}
</div>


								<Link style={{ color: 'white' }}  >{t('Detay')} <i className="ti-arrow-right"></i></Link>
							</div>
						</div>

					))}
						{this.state.data.map((item) => (
						<div className="slider-item">
							<div className="process-box">
								<h5 className="title">{language === "en" ? item.EngineeringServices_Category_TitleEng : item.EngineeringServices_Category_Title}</h5>

								<div className="process-btn">
  {item.EngineeringServices.map((item2, index) => (
    <React.Fragment key={index}>
      <Link   style={{ 
		    textTransform: 'capitalize' ,
    color: 'white',
    fontFamily: 'Noto Sans', // Kullanmak istediğiniz fontun adını belirtin
    fontSize: '16px', // Yazı tipi boyutunu belirleyebilirsiniz
    fontWeight: 'bold' // Yazı tipi kalınlığını belirleyebilirsiniz
  }}
   to={{ pathname: '/muhendislikhizmetleri_', state: { _item: item.EngineeringServices.length> 0? item.EngineeringServices[0] : [], _item2: item } }}>
        • {language === "en" ? item2.TitleEng_Short : item2.Title_Short}
      </Link>
      <br />
    </React.Fragment>
  ))}
</div>


								<Link style={{ color: 'white' }} >{t('Detay')} <i className="ti-arrow-right"></i></Link>
							</div>
						</div>

					))}


				</Slider>

			</>
		);
	}
}
export default withTranslation()(ServiceSlider1); // withTranslation ile sarmalandı

