import React from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next)

  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "Hizmetler": "Choose service",
          "TeklifAl": "Get a Quote",
          "İletişim":"Contact",
          "Detay":"Detail",
          "BizKimiz":"Why U.S?",
          "HDFB":"More about us",
          "BUİ":"To reach us",
          "BY":"Blog post",
          "SUT":"Offers suitable for you",
          "Danışmanlık":"CONSULTANCY",
          "BU":"Contact Us",
          "İsminiz":"Your Name",
          "Emailiniz":"Your Email",
          "Telefon":"Your Mobile Phone",
          "Mesaj":"Your Message",
          "Gönder":"Send",
          "İletisimYazi":"You can contact us for our consultancy services and more.",
          "SonBlog":"Our Latest Blogs",
          "DahaFazlası":"Click For More",
          "Anasayfa":"Home",
          "Adres":"Address",
          "TelefonNumarası":"Mobile Phone",
          "EmailAdres":"Email Address",
          "TeklifAlBizden": "Get an offer from us",
          "Vizyonumuz":"Our vision",
          "Misyonumuz":"Our Mission",
          "NedenBiz":"why U.S?",
          "Konu":"Subject",
          "Etiket":"Tags",
          "FED":"Fill out the form completely and contact us.",
          "Devam":"Continue",
          "MH":"Engineering Services",
          "DH":"Consultancy Services",
          "Sayfa":"Pages",
          "Firma":"Company",
          "Ekibimiz":"Our Team",
        }
        
      },
      tr: {
        translation: {
          "Hizmetler": "Hİzmet seçİnİz",
          "TeklifAl": "Teklif Al",
          "İletişim":"İletişim",
          "Detay":"Detay",
          "BizKimiz":"Neden Biz",
          "Ekibimiz":"Ekibimiz",
          "HDFB":"Hakkımızda daha fazla bilgi",
          "BUİ":"Bize ulaşmak için",
          "BY":"Blog yazıları",
          "SUT":"Size uygun teklifler",
          "Danışmanlık":"DANIŞMANLIK",
          "BU":"Bize Ulaşın",
          "İsminiz":"İsminiz",
          "Firma":"Firma",
          "Emailiniz":"Email",
          "Telefon":"Telefon Numaranız",
          "Mesaj":"Mesajınız",
          "Gönder":"Gönder",
          "İletisimYazi":"Danışmanlık hizmetlerimiz ve daha fazlası için bize ulaşabilirsiniz.",
          "SonBlog":"Son Bloglarımız",
          "DahaFazlası":"Daha Fazlası",
          "Anasayfa":"Anasayfa",
          "Adres":"Adres",
          "TelefonNumarası":"Telefon Numarası",
          "EmailAdres":"Email Adres",
          "TeklifAlBizden": "Bizden teklif al",
          "Vizyonumuz":"Vizyonumuz",
          "Misyonumuz":"Misyonumuz",
          "NedenBiz":"NedenBiz?",
          "Konu":"Konu",
          "Etiket":"Etiketler",
          "FED":"Formu eksiksiz doldurup bizimle iletişime geçin.",
          "Devam":"Devam",
          "MH":"Mühendislik Hizmetleri",
          "DH":"Danışmanlık Hizmetleri",
          "Sayfa":"Sayfalar"
          

        }
      },
    },
    lng: "tr", // if you're using a language detector, do not define the lng option
    fallbackLng: "tr",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

