import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
class Services2 extends Component{
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir


		return(
			<>
				<div className="section-area section-sp1 bg-white">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-6 wow fadeInUp m-md-b30" data-wow-delay="0.3s">
								<div className="feature-container feature-bx2">
									<div className="feature-lg text-white m-b20">
										<span className="icon-cell"><i className="ti-world"></i></span> 
									</div>
									<div className="icon-content">
										<h4 className="ttr-tilte">{t('İletişim')}</h4>
										<p>{t('BUİ')}</p>
										<Link to="iletisim" className="feature-btn"><i className="la la-long-arrow-alt-right"></i></Link>
									</div>
									<i className="ti-world bg-icon"></i>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 wow fadeInUp m-md-b30" data-wow-delay="0.6s">
								<div className="feature-container feature-bx2">
									<div className="feature-lg text-white m-b20">
										<span className="icon-cell"><i className="ti-comment"></i></span> 
									</div>
									<div className="icon-content">
										<h4 className="ttr-tilte">Blog</h4>
										<p>{t('BY')}</p>
										<Link to="blog" className="feature-btn"><i className="la la-long-arrow-alt-right"></i></Link>
									</div>
									<i className="ti-comment bg-icon"></i>
								</div>
							</div>
							<div className="col-lg-4 col-md-12 wow fadeInUp m-md-b30" data-wow-delay="0.9s">
								<div className="feature-container feature-bx2">
									<div className="feature-lg text-white m-b20">
										<span className="icon-cell"><i className="ti-home"></i></span> 
									</div>
									<div className="icon-content">
										<h4 className="ttr-tilte">{t('Anasayfa')}</h4>
										<p>{t('Anasayfa')}</p>
										<Link to="teklifal" className="feature-btn"><i className="la la-long-arrow-alt-right"></i></Link>
									</div>
									<i className="ti-home bg-icon"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default withTranslation()(Services2); // withTranslation ile sarmalandı

