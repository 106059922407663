import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import IconPdf from '../../../images/icon/pdf.png';
import IconDoc from '../../../images/icon/doc.png';
import AboutPic3 from '../../../images/about/pic3.jpg';
import HeadPhones from '../../../images/icon/headphones.png';
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
class SolutionSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {


			data3: [],
			data4: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {
		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getConsultancyCastor/select/";
		const apiUrl4 = "https://getjob.stechomeyazilim.info:5101/getEngineeringServicesCastor/select/";

		// Fetch data for consultancy
		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("Consultancy API'den veri çekme hatası:", error);
			});

		// Fetch data for engineering services
		fetch(apiUrl4)
			.then((response) => response.json())
			.then((data4) => {
				this.setState({ data4 });
				console.log("data4", data4);
			})
			.catch((error) => {
				console.error("Engineering Services API'den veri çekme hatası:", error);
			});

		// Cart Dropdown 
		var cartBtn = document.getElementById("cartBtn")
		var cartDropdown = document.querySelector(".cart-dropdown")

		cartBtn.addEventListener('click', function () {
			cartDropdown.classList.toggle("show")
		})

		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
		var searchForm = document.querySelector(".nav-search-bar")
		var closeBtn = document.getElementById("search-remove")

		searchBtn.addEventListener('click', function () {
			searchForm.classList.add("show")
		})

		closeBtn.addEventListener('click', function () {
			searchForm.classList.remove("show")
		})

		// Mobile Menu sidebar function
		var btn = document.querySelector('.menuicon');
		var nav = document.querySelector('.menu-links');

		function toggleFunc() {
			btn.classList.toggle("open");
			nav.classList.toggle("show");
		}

		btn.addEventListener('click', toggleFunc);

		// Mobile Submenu open close function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}

		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));

			if (active) {
				current.classList.remove('open')
				console.log("active")
			} else {
				current.classList.add('open');
				console.log("close")
			}
		}

	}

	render() {
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir
		const language = i18n.language

		return (
			<>
				<aside className="sticky-top">
					<div className="widget">
						<ul className="service-list">


							{this.state.data4.map((item3) => (
								<li><Link to={{ pathname: '/muhendislikhizmetleri', state: { _item: item3 } }} >
									 {language == "en" ? 
									 <span>{item3.TitleEng}</span> :
									 <span>{item3.Title}</span>}

										<i className="fa fa-angle-right"></i> </Link></li>
							))}

						</ul>
					</div>
					{/*
					<div className="widget">
						<div className="brochure-bx">
							<h5 className="title-head">Download</h5>
							<Link to="#" className="download-link">
								<img src={IconPdf} alt=""/>
								<h5 className="title">Download our Brochures</h5>
								<span>Download</span>
							</Link>
							<Link to="#" className="download-link">
								<img src={IconDoc} alt=""/>
								<h5 className="title">Our Company Details</h5>
								<span>Download</span>
							</Link>
						</div>
					</div>
					<div className="widget">
						<div className="help-bx">
							<div className="media">
								<img src={AboutPic3} alt="" />
							</div>
							<div className="info text-white">
								<img src={HeadPhones} alt="" />
								<p> </p>
								<a href="teklifver" className="btn-secondry">{t('TeklifAlBizden')}</a>
							</div>
						</div>
					</div>
					
					*/}
					
				</aside>
			</>
		);
	}
}
export default withTranslation()(SolutionSidebar); // withTranslation ile sarmalandı


