import React , {useEffect,useState}from 'react';
import Markup from './markup/markup';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import "toastr/build/toastr.min.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import 'react-modal-video/css/modal-video.min.css';

import "swiper/swiper-bundle.min.css";

// Fonts
import './vendors/fontawesome/css/font-awesome.min.css';
import './vendors/flaticon/flaticon.css';
import './vendors/flaticon/light/flaticon1.css';
import './vendors/line-awesome/css/line-awesome.css';
import './vendors/themify/themify-icons.css';

// Stylesheet
import './css/typography.css';
import './css/shortcodes/shortcodes.css';
import './css/style.css';
import './css/color/color-1.css';

function App() {
	const [data2, setData2] = useState([]);


	const handleWhatsAppClick = () => {
		const whatsappURL = `https://api.whatsapp.com/send?phone=${data2[0].Phone}` 
		window.open(whatsappURL, '_blank');
	};

useEffect(() => {
        const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactCastor/select/";

        fetch(apiUrl2)
            .then((response) => response.json())
            .then((data) => {
                setData2(data);
            })
            .catch((error) => {
                console.error("API'den veri çekme hatası:", error);
            });
    }, []); // Boş bağımlılık dizisi, bileşenin ilk render edildiğinde yalnızca bir kez çalışmasını sağlar


	return (
		<div className="App">
			<Markup />
			<div
				style={{
					position: 'fixed',
					bottom: '75px',
					right: '35px',
					cursor: 'pointer',
					borderRadius: '20px',
					width: '53px', // Genişliği arttırabilirsiniz
					height: '53px', // Yüksekliği arttırabilirsiniz
					backgroundColor: '#25D366', // Arkaplan rengi
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Gölge efekti
				}}
				onClick={handleWhatsAppClick}
			>
				<i style={{ fontSize: 35, color: '#FFFFFF' }} className="fa fa-whatsapp"></i>
			</div>

		</div>
	);
}

export default App;
