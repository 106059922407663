import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import { withTranslation } from 'react-i18next';
import MapComponent from './MapComponent';

class Contact1 extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactCastor/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t('İletişim')}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
										<li>{t('İletişim')}</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					
								
					<div className="page-banner contact-page bg-white">
						<div className="container-fuild">

							
							<div className="row m-lr0">


								
							{this.state.data2.length > 0 ? <div className="col-lg-6 col-md-6 p-lr0 d-flex">
							<MapComponent _item = {this.state.data2} />

								</div> : null}
								<div className="col-lg-6 col-md-6 section-sp2 p-lr30">
									<form className="form-area-box ajax-form mw700 m-auto" action="assets/script/contact.php">
									<div className="ajax-message"></div>
										<div className="heading-bx">
											<h6 className="title-ext m-b0">Castor</h6>
											<h2 className="title-head m-b0">{t('BU')}</h2>
									
											<p className="head-px2">{t('FED')}</p>
										</div>
										<div className="row placeani">
											<div className="col-lg-6 ">
												<div className="form-group">
													<div className="input-group">
														<label htmlFor="name">{t('İsminiz')}</label>
														<input name="name" type="text" required className="form-control valid-character"/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group"> 
														<label>{t('Emailiniz')}</label>
														<input name="email" type="email" className="form-control" required />
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<label>{t('Telefon')}</label>
														<input name="phone" type="text" required className="form-control int-value"/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<label>{t('Konu')}</label>
														<input name="subject" type="text" required className="form-control"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<label>{t('Mesaj')}</label>
														<textarea name="message" rows="4" className="form-control" required ></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn button-md"> {t('Gönder')}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default withTranslation()(Contact1); // withTranslation ile sarmalandı

